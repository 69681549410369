<template>
  <loader v-bind="{ loading }" text="Loading Equipment Classifications">
    <columns>
      <column>
        <page-heading
          heading="Checklist Classifications"
          sub-heading="Build and customize checklist templates your teams will use to perform work."
        />
      </column>
      <column class="is-5">
        <form>
            <text-input 
              v-model="filters.search"
              classes="has-addons is-rounded"
              placeholder="Search checkpoints...">
              <template #right>
                <div class="buttons has-addons is-rounded">
                  <submit-button 
                    @submit="runSearch"
                    class="is-rounded">
                    Search
                  </submit-button>
                  <action-button
                    v-if="$route.query.search"
                    @click="clearFilters">
                    <icon icon="times"/> 
                  </action-button>
                  <router-link 
                    class="button is-rounded is-flex" 
                    :to="{
                      name: 'create-checklist-classification'
                    }">
                      <icon icon="plus"/>
                      <span>Classification</span>
                    </router-link>
                </div>
              </template>
            </text-input>
          </form>
      </column>
    </columns>
    <columns>
      <column>
        <div class="index-rows">
          <div class="box is-marginless" v-for="checklistClassification in classifications.data" :key="checklistClassification.id">
            <columns>
              <column>
                <router-link :to="{
                  name: 'edit-checklist-classification',
                  params: {
                    checklistClassification: checklistClassification.uuid
                  }
                }">
                  {{ checklistClassification.name }}
                </router-link>
                <h6 class="subtitle is-6 has-text-grey is-flex">
                  Updated {{ checklistClassification.updated_at | asCalendar }}
                  <span 
                    v-tooltip="`This classification is only accessible by the segemented teams(${checklistClassification.segmented_teams_count}) it is assigned to.`"
                    class="has-text-warning" 
                    v-if="checklistClassification.segmented_teams_count">
                    <icon 
                      icon="exclamation-triangle"
                    />
                  </span>
                </h6>
              </column>
              <column class="is-narrow is-flex is-align-items-center is-justify-content-end">
                <index-info 
                  icon="list-check"
                  label="Checklists" 
                  :info="checklistClassification.checklists_count" 
                />
              </column>
            </columns>
          </div>
        </div>
        <pager v-if="!classifications.data.length" :pageable="classifications" context="Checklist Classification" jump-controls @nav="goToPage" />

        <no-items-to-display
          :item-count="classifications.data.length"
          with-link
          heading="There are no checklist classifications available for display."
          :to="{
            name: 'create-checklist-classification'
          }"
          to-text="New Checklist Classification"
        />
      </column>
    </columns>
  </loader>
</template>
<script>
import { get } from '@/api/request'
export default {

  data: () => ({
    loading: true,
    filters: {
      search: ''
    },
    classifications: {
      data: []
    }
  }),

  created() {
    if (this.$route.query) {
      this.filters.search = this.$route.query.search
    }
    this.loadChecklistClassifications()
  },

  methods: {
    loadChecklistClassifications() {
      get('v1' + this.$route.fullPath, ({ data }) => {
        this.classifications = data
        this.loading = false
      }, () => {
        this.loading = false
      })
    },
    goToPage(path) {
      this.$router.push(path)
    },
    runSearch() {
      this.$router.push({
        name: 'checklist-classifications',
        query: this.filters
      });
    },
    clearFilters() {
      this.filters.search = ''
      this.$router.push({
        name: 'checklist-classifications',
      });
    }
  },

  watch: {
    '$route': 'loadChecklistClassifications'
  }

}
</script>